// 科研院所
<template>
  <Lists />
</template>

<script>
import Lists from './components/Lists.vue'
export default {
  components: {
    Lists
  }
};
</script>

<style scoped lang="less">
</style>
